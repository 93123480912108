@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.container {
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.contentWrapper {
  background-color: $light-gray;

  @media #{$desktop} {
    padding: 250px 115px 115px;
  }

  @media #{$tablet} {
    padding: 150px 95px 100px;
  }

  @media #{$mobile} {
    padding: 150px 15px 70px;
  }
}

.title {
  margin: 0 auto;

  @media #{$desktop} {
    width: 485px !important;
    margin-bottom: 30px;
  }

  @media #{$tablet} {
    width: 400px !important;
    margin-bottom: 15px;
  }

  @media #{$mobile} {
    margin-bottom: 15px;
  }
}

.subtitle {
  @media #{$mobile} {
    margin: 0 auto;
    width: 320px !important;
  }
}

.image {
  position: relative;
  display: flex;
  justify-content: center;

  margin: 85px auto 0;
  height: 600px;
  width: 660px !important;

  @media #{$mobile} {
    margin-top: 70px;
    width: 180px !important;
  }

  @media #{$tablet} {
    margin-top: 30px;
    height: 550px;
    width: 550px !important;
  }

  @media #{$mobile} {
    height: 360px;
    width: min(100%, 360px) !important;
    margin-top: 15px;
  }
}
